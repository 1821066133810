import React from 'react'

export const CallToActionMobile = () => (
  <section className="call-to-action-mobile">
    <div className="container">
      <h2>We're here to help</h2>

      <a href="tel:1300 046 888">Call us on 1300 046 888</a>
    </div>
  </section>
)
