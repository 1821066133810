import React from 'react'
import { Link } from 'gatsby'

import coverage from '../../assets/images/icon-mobile-coverage.svg'

export const CheckCoverageBanner = () => (
  <section className="check-your-coverage">
    <div className="container">
      <div className="col-12 text-center">
        <a
          href="/mobile-health-map"
          className="btn btn-rounded btn-navy-outline text-center"
        >
          Check your status
        </a>
        <img
          src={coverage}
          className="d-block mx-auto my-4 d-md-inline-block mx-md-4"
          alt="Check your coverage"
        />
        <a
          href="/mobile-coverage-map"
          className="btn btn-rounded btn-navy-outline text-center"
        >
          Check your coverage
        </a>
      </div>
    </div>
  </section>
)
