import React from 'react'
import Modal from 'react-modal'

const disclaimerData = [
  {
    title: '19 numbers',
    content: (
      <p>
        Premium SMS and calls are barred by default. For assistance in enabling
        premium services please email{' '}
        <a href="mailto:support@commtel.au">support@commtel.au</a> or call{' '}
        <a href="tel:1300 046 888">1300 046 888</a>
      </p>
    ),
  },
  {
    title: 'Directory assistance (12 numbers)',
    content: <p>$0.48/call</p>,
  },
  {
    title: 'International calls',
    content: (
      <p>
        Check our{' '}
        <a href="/pdf/international-call-rates.pdf" target="_blank">
          International call rates
        </a>
      </p>
    ),
  },
  {
    title: 'International roaming',
    content: (
      <p>
        See our{' '}
        <a href="/pdf/pricing-schedule.pdf" target="_blank">
          Pricing Schedule
        </a>{' '}
        for calls and data roaming
      </p>
    ),
  },
  {
    title: 'Third party content',
    content: (
      <p>
        Third party content like ringtones, wallpapers, applications, social
        media, SMS alerts or any other non-Commtel broadband service
      </p>
    ),
  },
  {
    title: '124 YES',
    content: (
      <p>
        $1.68 per call, extra $0.25 if search result is sent as a text message
        to your mobile phone
      </p>
    ),
  },
]

export class ReadMoreModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.openModal}
        onRequestClose={() => this.props.closeModal()}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '999',
            overflow: 'none',
            backgroundColor: '#fff',
          },
        }}
        ariaHideApp={false}
        contentLabel="Exclusion details"
      >
        <div>
          <div className="plan-selector__header">
            <button
              type="button"
              className="close plan-selector__close-btn"
              onClick={() => this.props.closeModal()}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h2>What's excluded?</h2>
          </div>
          <br />
          <div className="exclusions-container">
            <ul className="exclusions list">
              {disclaimerData.map(item => (
                <li style={{ alignItems: 'center' }} key={item.title}>
                  <p>
                    <span className="exclusion-title">{item.title}</span>
                    {item.content}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    )
  }
}
